import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MovingElement from "@elements/MovingElement"
import parse from "html-react-parser"

const Section = styled.section`
  ${tw`relative -mt-20 md:-mt-32 2xl:-mt-48 pb-c96 mb-c120 xs:mb-c300 md:mb-0`}
  z-index: -1;
`
const Spacer = styled.div`
  ${tw`pt-32 md:pt-60 2xl:pt-80`}
`
const Content = styled.div`
  ${tw`grid md:grid-cols-12`}
`
const BackgroundImage = styled.div`
  ${tw`absolute top-0 left-0 right-0 bottom-0`}
  box-shadow: inset 0px 0px 40px 30px rgba(0,0,0,0.2);
`
const TextColumn = styled.div`
  ${tw`relative flex flex-col justify-center md:min-h-20vw min-h-50vw md:col-span-5`}
`

const HeadlineWrap = styled.div`
  ${tw`2xl:text-5xl text-4xl font-black relative z-10 font-ubuntu font-black`}
`
const Description = styled.div`
  ${tw`relative z-10 font-normal lg:pr-12`}
  &.simple-description {
    ::before {
      ${tw`absolute top-c40 md:top-c32 2xl:top-c40 -left-5`}
      content: '/*';
    }
    &::after {
      ${tw`absolute bottom-0 -left-5`}
      content: '*/';
    }
    & span.line {
      ${tw`absolute -left-3 top-c68 md:top-c60 2xl:top-c68 bottom-8 w-px bg-black bg-gray-500`}
    }
  }
`

const ImagesColumn = styled.div`
  ${tw`flex relative w-full md:col-span-7`}
`
const ImageWrap = styled.div`
  ${tw`w-1/2 relative`}
`

const MobilePatternRef = ({ moduleData }) => {
  const blockData = moduleData?.acfRefPatternTextImageBlock
  const sectionAttributes = moduleData?.attributes
  const sectionId = sectionAttributes?.anchor || ""

  const image01 = getImage(blockData.image?.localFile)
  const image02 = getImage(blockData.secondImage?.localFile)

  return (
    <Section id={sectionId}>
      {blockData.backgroundVariant === "color" && (
        <BackgroundImage
          style={{
            backgroundColor: blockData.backgroundColor,
          }}
        />
      )}
      {blockData.backgroundVariant === "image" && (
        <BackgroundImage
          style={{
            backgroundImage: `url(${blockData.backgroundImage?.localFile?.publicURL})`,
          }}
        />
      )}
      <Spacer />
      <Content>
        <TextColumn className="px-offset sm:px-12 lg:px-16 xl:pl-24 xl:pr-0">
          <HeadlineWrap>
            <p
              style={{ color: blockData.overlineColor }}
              className="overline-section"
            >
              {blockData.overline}
            </p>
            <h2
              style={{ color: blockData.headlineColor }}
              className="headline-section font-ubuntu font-bold relative"
            >
              <span
                className="relative"
                data-start-symbol={blockData.startSymbol}
                data-end-symbol={blockData.endSymbol}
              >
                {parse(blockData.headline)}
              </span>
            </h2>
          </HeadlineWrap>
          <Description
            style={{ color: blockData.descriptionColor }}
            className={`copy simple-description`}
          >
            {parse(blockData.description)}
            <span className="line"></span>
          </Description>
        </TextColumn>
        <ImagesColumn>
          <ImageWrap className="2xl:mt-10">
            <MovingElement
              speed={0.05}
              direction={{
                negative: "-",
                axis: "y",
              }}
              content={false}
              className="transition-all duration-500 ease-linear"
            >
              {image01 !== null && image01 !== undefined && (
                <GatsbyImage
                  className="!absolute w-full left-0 right-0"
                  image={image01}
                  alt=""
                />
              )}
            </MovingElement>
          </ImageWrap>
          <ImageWrap className="xs:-mt-20 md:mt-0 2xl:-mt-20">
            <MovingElement
              speed={0.03}
              direction={{
                negative: "",
                axis: "y",
              }}
              content={false}
              className="transition-all duration-300 ease-linear"
            >
              {image02 !== null && image02 !== undefined && (
                <GatsbyImage
                  className="!absolute w-full left-0 right-0"
                  image={image02}
                  alt=""
                />
              )}
            </MovingElement>
          </ImageWrap>
        </ImagesColumn>
      </Content>
    </Section>
  )
}

MobilePatternRef.propTypes = {
  moduleData: PropTypes.object,
}

export default MobilePatternRef
